.field {
  margin: 1rem;
}

.inputFeedbackGrouping {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.Input {
  border: 1px solid rgba(var(--rgbValuesSecondary), 0.5);
  border-radius: 3px;
  font-size: 1.125rem;
  padding: 0.5rem;
  min-width: 200px;
}

.Input[type="number"] {
  inline-size: auto;
}

.Input:focus {
  border-color: var(--primary);
  box-shadow: 0 0 1px 1px rgba(var(--rgbValuesPrimary), 0.75);
  outline: none;
}

.Input:disabled {
  opacity: 0.6;
}

.Input:disabled:hover {
  cursor: not-allowed;
}

.errorMessage {
  /* Alert's border is only 1px - not 2px (account for Input's left and right border) */
  max-width: calc(100% + 2px);
  margin-top: 0.5rem;
  flex: 1;
  text-align: center;
}

.valid {
  border-color: var(--successDeep);
  box-shadow: 0 0 1px 1px var(--successDeep);
  outline: none;
}

.invalid {
  border-color: var(--errorDeep);
  box-shadow: 0 0 1px 1px var(--errorDeep);
  outline: none;
}

@media screen and (--desktop-viewport) {
  .inputFeedbackGrouping {
    position: relative;
  }

  .errorMessage {
    margin-top: 0;
    margin-left: 1rem;
    position: absolute;
    top: -2px;
    left: 100%;
    min-width: 150px;
    max-width: 300px;
    width: auto;
    padding: 0 10px;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
