.SocialLoginGroup {
  width: 100%;
}

.flexRow {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.fullWidth {
  width: 100%;
}

/* Shared */
.loginButton {
  font-family: Helvetica, sans-serif;
  width: 260px;
  box-sizing: border-box;
  position: relative;
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 14px;
  color: #ffffff;
}

.loginButton::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}

.loginButton:active {
  opacity: 0.75;
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Alert Message */
.alertContainer {
  margin-bottom: 1rem;
}

.alertFill {
  max-width: 500px;
  margin: 0 auto;
}

/* Facebook */
.facebookButton {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  text-shadow: 0 -1px 0 #354c8c;
  transition: opacity 0.15s linear;
}

.facebookButton::before {
  border-right: #364e92 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png") 6px 6px
    no-repeat;
}

.facebookButton:active,
.facebookButton:hover,
.facebookButton:focus {
  opacity: 0.8;
  cursor: pointer;
}

/* Google */
.googleButton {
  background: #dd4b39;
  transition: opacity 0.15s linear;
}

.googleButton::before {
  border-right: #bb3f30 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png") 6px 6px
    no-repeat;
}

.googleButton:active,
.googleButton:hover,
.googleButton:focus {
  opacity: 0.8;
  cursor: pointer;
}
