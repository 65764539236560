.Alert {
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 3px;
  box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.12);
  font-size: 0.825rem;
  padding: 0.75rem;
}

.error {
  background-color: var(--error);
  border-color: var(--errorDeep);
  color: var(--errorDeep);
}

.success {
  background-color: var(--success);
  border-color: var(--successDeep);
  color: var(--successDeep);
}

.warning {
  background-color: var(--warning);
  border-color: var(--warningDeep);
  color: var(--warningDeep);
}

.alertCloseButton {
  border: none;
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
  font-size: 1.5rem;
  padding: 0 0.5rem;
  margin: 0 0.25rem;
  vertical-align: middle;
  transform: scale(1);
  transition: 0.2s color linear, 0.2s transform linear;
}

.alertCloseButton:hover {
  color: black;
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.2s color linear, 0.2s transform linear;
}
